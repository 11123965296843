import React from 'react';


import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Header.css';

const Header = () => (
  <div className="app__header app__wrapper section__padding" id="home">
    <div className="app__wrapper_info">
      <SubHeading title="Delta asa cum nu ai mai vazut-o" />
      <h1 className="app__header-h1">Descopera Delta si satul pescaresc Murighiol</h1>
      <p className="p__opensans" style={{ margin: '2rem 0' }}>Murighiol este o comună în județul Tulcea, Dobrogea, România, formată din satele Colina, Dunavățu de Jos, Dunavățu de Sus, Murighiol (reședința), Plopul, Sarinasuf și Uzlina. Numele provine de la cel al satului de reședință, este de origine turcă și înseamnă lacul vânăt, probabil datorită culorii cenușii pe care o capătă uneori apele lacului din apropierea satului. Este cea mai întinsă comună din România </p>
      <a href="https://ro.wikipedia.org/wiki/Comuna_Murighiol,_Tulcea" target="_blank" rel="noreferrer"><button type="button" className="custom__button" alt="casacustufMurighiol">Citeste</button> </a>
      
    </div>

    <div className="app__wrapper_img" >
      
        <a href="https://www.instagram.com/casacustufmurighiol/" target='_balnk' alt='casacustufMurighiol'>
        <img src={images.findus} alt="header_img" />
         </a>

      </div>
   
    </div>
  
);

export default Header;
