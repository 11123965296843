import React from 'react';
import { BsInstagram, BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWifi,faTv,faBath,faBed,faVideoCamera } from "@fortawesome/free-solid-svg-icons"


import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Gallery.css';

const Gallery = () => {
  const scrollRef = React.useRef(null);

  const scroll = (direction) => {
    const { current } = scrollRef;

    if (direction === 'left') {
      current.scrollLeft -= 300;
    } else {
      current.scrollLeft += 300;
    }
  };

  return (
    <div className="app__gallery flex__center" id="gallery">
      <div className="app__gallery-content">
        <SubHeading title="Photos" />
        <h1 className="headtext__cormorant">Photo Gallery</h1>
        <h1>
          <FontAwesomeIcon icon={faWifi} style={{color: 'white', padding:10}} />
           <FontAwesomeIcon icon={faTv} style={{color: 'white', padding:10}}/> 
            <FontAwesomeIcon icon={faBath} style={{color: 'white', padding:10}}/> 
            <FontAwesomeIcon icon={faBed} style={{color: 'white', padding:10}}/> 
            <FontAwesomeIcon icon={faVideoCamera} style={{color: 'white', padding:10}}/> 
            </h1> 

        <p className="p__opensans" style={{ color: '#AAAAAA', marginTop: '2rem' }}>CasaCuStufMurighiol are doua camera ce pot gazdui 2 respectiv 3 persoane si dispune de Wifi cat si de Smart TV pentru fiecare camera.</p>
       
        <a href="https://www.instagram.com/casacustufmurighiol/" target="_blank" rel="noreferrer"><button type="button" className="custom__button">Click pentru mai multe poze</button></a>
      </div>
      <div className="app__gallery-images">
        <div className="app__gallery-images_container" ref={scrollRef}>
          {[images.gallery01, images.gallery02, images.gallery03, images.gallery04,images.gallery05,images.gallery06,images.gallery07,images.gallery08,images.gallery09,images.gallery10].map((image, index) => (
            <a href="https://www.instagram.com/casacustufmurighiol/" target='_balnk' alt='casacustufMurighiol'>
            <div className="app__gallery-images_card flex__center" key={`gallery_image-${index + 1}`}>
              <img src={image} alt="gallery_image" />
              <BsInstagram className="gallery__image-icon" />
            </div>
            </a>
          ))}
        </div>
        <div className="app__gallery-images_arrows">
          <BsArrowLeftShort className="gallery__arrow-icon" onClick={() => scroll('left')} />
          <BsArrowRightShort className="gallery__arrow-icon" onClick={() => scroll('right')} />
        </div>
      </div>
    </div>
  );
};

export default Gallery;
