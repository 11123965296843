import images from './images';

const cameras = [
  {
    title: 'Camera 2 persoane',
    price: '300 RON',
    
  },
  {
    title: 'Camera 3 persoane',
    price: '350 RON',
    
  },
  {
    title: 'Toata Casa',
    price: '650 RON',
    
  },
  {
    title: 'Breakfast la cerere',
    price: '40 RON/pers',
    
  },
  // {
  //   title: 'Rhino Pale Ale',
  //   price: '$31',
  //   tags: 'CA | 750 ml',
  // },
  // {
  //   title: 'Irish Guinness',
  //   price: '$26',
  //   tags: 'IE | 750 ml',
  // },
];

const mancares = [
  {
    title: 'Omleta specialitatea casei',
    
    // tags: 'Aperol | Villa Marchesi prosecco | soda | 30 ml',
  },
  {
    title: "Bors de peste traditional",
    // price: '$16',
    // tags: 'Dark rum | Ginger beer | Slice of lime',
  },
  {
    title: 'Plachie de peste',
    // price: '$10',
    // tags: 'Rum | Citrus juice | Sugar',
  },
  {
    title: 'Peste prajit/la gratar',
    // price: '$31',
    // tags: 'Bourbon | Brown sugar | Angostura Bitters',
  },
  {
    title: 'Ciorba de burta specialitatea casei',
    // price: '$26',
    // tags: 'Gin | Sweet Vermouth | Campari | Orange garnish',
  },
];

const awards = [
  {
    imgUrl: images.award02,
    title: 'Bib Gourmond',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award01,
    title: 'Rising Star',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award05,
    title: 'AA Hospitality',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award03,
    title: 'Outstanding Chef',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
];



export default { cameras, mancares, awards };
