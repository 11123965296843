import React from 'react';

import { SubHeading, MenuItem } from '../../components';
import { data, images } from '../../constants';
import './SpecialMenu.css';

const SpecialMenu = () => (
  <div className="app__specialMenu flex__center section__padding" id="menu">
    <div className="app__specialMenu-title">
      <SubHeading title="Masa autentica Dobrogeana" />
      <h1 className="headtext__cormorant">Preturi</h1>
      <SubHeading title="Toate mancarurile se fac la cerere si trebuie anuntat din timp!!!
" />    </div>

    <div className="app__specialMenu-menu">
      <div className="app__specialMenu-menu_wine  flex__center">
        <p className="app__specialMenu-menu_heading">Preturi</p>
        <div className="app__specialMenu_menu_items">
          {data.cameras.map((camera, index) => (
            <MenuItem key={camera.title + index} title={camera.title} price={camera.price} tags={camera.tags} />
          ))}
        </div>
      </div>

      <div className="app__specialMenu-menu_img">
        <img src={images.menu} alt="menu__img" />
      </div>

      <div className="app__specialMenu-menu_cocktails  flex__center">
        <p className="app__specialMenu-menu_heading">Menu traditional(la cerere)</p>
        <div className="app__specialMenu_menu_items">
          {data.mancares.map((mancare, index) => (
            <MenuItem key={mancare.title + index} title={mancare.title} price={mancare.price} tags={mancare.tags} />
          ))}
        </div>
      </div>
    </div>

    {/* <div style={{ marginTop: 15 }}>
      <button type="button" className="custom__button">View More</button>
    </div> */}
  </div>
);

export default SpecialMenu;
