import bg from '../assets/bg.webp';


import gallery01 from '../assets/gallery01.webp';
import gallery02 from '../assets/gallery02.webp';
import gallery03 from '../assets/gallery03.webp';
import gallery04 from '../assets/gallery04.webp';
import gallery05 from '../assets/gallery05.webp';
import gallery06 from '../assets/gallery06.webp';
import gallery07 from '../assets/gallery07.webp';
import gallery08 from '../assets/gallery08.webp';
import gallery09 from '../assets/gallery09.webp';
import gallery10 from '../assets/gallery10.webp';

import logo from '../assets/logo.webp';
import menu from '../assets/menu.webp';
import overlaybg from '../assets/overlaybg.png';
import spoon from '../assets/spoon.png';
import welcome from '../assets/welcome.png';
import findus from '../assets/findus.webp';


import sign from '../assets/sign.png';

import gericht from '../assets/gericht.png';

import history1 from '../assets/history1.jpeg';
import history2 from '../assets/history2.jpeg';
import history3 from '../assets/history3.jpeg';
import history4 from '../assets/history4.webp';
import history5 from '../assets/history5.webp';
import history6 from '../assets/history6.webp';




export default {
  bg,
  

  gallery01,
  gallery02,
  gallery03,
  gallery04,
  
  gallery05,
  gallery06,
  gallery07,
  gallery08,
  gallery09,
  gallery10,
  
  logo,
  menu,
  overlaybg,

  
  welcome,
  findus,

 
  sign,
  
  gericht,
  
  spoon,
  history1,
  history2,
  history3,
  history4,
  history5,
  history6,
};
