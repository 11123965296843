import React from 'react';



import { BsInstagram, BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';

import { SubHeading } from '../../components';

import { images } from '../../constants';
import './AboutUs.css';

// const AboutUs = () => (

 



//   <div className="app__aboutus app__bg flex__center section__padding" id="about">
//     {/* <div className="app__aboutus-overlay flex__center">
//       <img src={images.G} alt="G_overlay" />
//     </div> */}

//     <div className="app__aboutus-content flex__center">
//       {/* <div className="app__aboutus-content_about">
//         <h1 className="headtext__cormorant">About Us</h1>
//         <img src={images.egreta} alt="about_spoon" className="spoon__img" />
//         <p className="p__opensans">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis pharetra adipiscing ultrices vulputate posuere tristique. In sed odio nec aliquet eu proin mauris et.</p>
        
//       </div> */}

//       {/* <div className="app__aboutus-content_knife flex__center">
//         <img src={images.knife} alt="about_knife" />
//       </div> */}


        
           
      

//       <div className="app__aboutus-content_history">
//         <h1 className="headtext__cormorant">Our History</h1>
//         <img src={images.spoon} alt="about_spoon" className="spoon__img" />
//         <p className="p__opensans">Casa avand peste 100 de ani a fost cumparata in anul 2002 si i-a apartinut preotului satului din acele vremuri, iar noi am inceput restaurarea in anul 2020 incercand sa pastram cat mai mult din autenticitatea acesteia .</p>
     
//       </div>
//     </div>
//   </div>
// );

// export default AboutUs;



      
        
const AboutUs = () => {
  const scrollRef = React.useRef(null);

  const scroll = (direction) => {
    const { current } = scrollRef;

    if (direction === 'left') {
      current.scrollLeft -= 300;
    } else {
      current.scrollLeft += 300;
    }
  };

  return (
    <div className="app__gallery flex__center" id="about">
      <div className="app__gallery-content">
        <SubHeading title="Cum am inceput" />
        <h1 className="headtext__cormorant">Povestea Noastra</h1>
        <p className="p__opensans" style={{ color: '#AAAAAA', marginTop: '2rem' }}>Casa are peste 100 de ani a fost cumparata in anul 2002 si i-a apartinut preotului satului din acele vremuri, iar noi am inceput restaurarea in anul 2020 incercand sa pastram cat mai mult din autenticitatea acesteia .</p>
        <a href="https://www.instagram.com/casacustufmurighiol/" target="_blank" rel="noreferrer" alt="casacustufMurighiol"><button type="button" className="custom__button">Click pentru mai multe poze</button>       </a>
      </div>
      <div className="app__gallery-images">
        <div className="app__gallery-images_container" ref={scrollRef}>
          {[ images.history1, images.history2, images.history3, images.history4,images.history5,images.history6].map((image, index) => (
            <a href="https://www.instagram.com/casacustufmurighiol/" target='_balnk' alt="casacustufMurighiol">
            <div className="app__gallery-images_card flex__center" key={`gallery_image-${index + 1}`}>
              <img src={image} alt="gallery_image" />
              <BsInstagram className="gallery__image-icon" />
            </div>
            </a>
          ))}
        </div>
        <div className="app__gallery-images_arrows">
          <BsArrowLeftShort className="gallery__arrow-icon" onClick={() => scroll('left')} />
          <BsArrowRightShort className="gallery__arrow-icon" onClick={() => scroll('right')} />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;


