import React from 'react';
import './FindUs.css';
import GoogleMapReact from 'google-map-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone,faEnvelope} from "@fortawesome/free-solid-svg-icons"



import { SubHeading } from '../../components';

import { images } from '../../constants';




function Mailto({ email, subject, body, ...props }) {
  return (
    <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
      {props.children}
    </a>
  );
}


const AnyReactComponent = ({ text }) => <div class="marker"><span class="beacon">{text}</span></div>;


  const defaultProps = {
    center: {
      lat: 45.03841646988186,
      lng: 29.166986625670187
    },
    zoom: 12
  };

  
    // Important! Always set the container height explicitly
   
  




const FindUs = () => (

  
 
  <div className="app__bg app__wrapper section__padding" id="contact">
    <div className="app__wrapper_info">
      <SubHeading title="Contact" />
      <h1 className="headtext__cormorant" style={{ marginBottom: '3rem' }}>Find Us</h1>
      <div className="app__wrapper-content">
      <p className="p__cormorant" style={{ color: '#DCCA87', margin: '2rem 0' }}> Adresa</p>
        <p className="p__opensans">Str. Egretei, Nr. 25, Sat Murighiol, Com. Murighiol, Jud.Tulcea</p>
        <p className="p__cormorant" style={{ color: '#DCCA87', margin: '2rem 0' }}>Rezervări</p>

        <div itemscope itemtype="http://schema.org/LocalBusiness" className="p__opensans">
              
        
              <span itemprop="telephone" >
                <a href="tel:+40 740085585">
                <FontAwesomeIcon icon={faPhone} style={{color: 'white', padding:10}} /> +40 740-085-585
                </a>
                </span>
               
                <br />
                <span itemprop="telephone" >
                <a href="tel:+40 745173168">
                <FontAwesomeIcon icon={faPhone} style={{color: 'white', padding:10}} /> +40 745-173-168
                     
                </a>
              </span>
              <br></br>
              
           
                <span>
        
        
              <Mailto email="rezervari@casacustufmurighiol.com" subject="Hello" body="....">
          
                <p> <FontAwesomeIcon icon={faEnvelope} style={{color: 'white', padding:10}} />rezervari@casacustufmurighiol.com</p>
              
              </Mailto>

                </span>
              
            </div>
        
        

   
       

        <br></br>

        <div style={{ height: '30vh', width: '100%' }}>
      <a href="https://www.google.com/maps/dir/44.4512052,26.133894/google+maps+casa+cu+stuf+murighiol/@44.5657426,25.4046934,7z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x40b9eda428468189:0x8e13f4fc9215a3af!2m2!1d29.1670107!2d45.0382863" alt="casacustufMurighiol" target="_blank" rel="noreferrer">
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCKoDNNzI4wC1-5ScLoOc1GeM1HLQ5qUGw" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <AnyReactComponent
        
          lat={45.03841646988186}
          lng={29.166986625670187}
          text="Direction"
        />
      </GoogleMapReact>

      </a>
      
    </div>
  

      </div>
      <a href="https://www.google.com/maps/dir//google+maps+casa+cu+stuf+murighiol/@45.0494396,29.1234083,12z/data=!4m9!4m8!1m0!1m5!1m1!1s0x40b9eda428468189:0x8e13f4fc9215a3af!2m2!1d29.1670107!2d45.0382863!3e0" target="_blank" rel="noreferrer" alt="casacustufMurighiol"><button type="button" className="custom__button" style={{ marginTop: '2rem' }}>Direction</button></a>
    </div>

    <div className="app__wrapper_img">
      <img src={images.findus} alt="finus_img" />
    </div>

  
    
  </div>
);




export default FindUs;
